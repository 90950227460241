import axios from "globals/services/api";

const DataAPI = {
  getActiveProjects() {
    return axios
      .get("/api/projects/getActiveProjects")
      .then((data) => {
        return data.Projects;
      })
      .catch((err) => {
        return [];
      });
  },
  getParametersLastUpdate(userId) {
    return axios
      .get("/Parameters/lastUpdateTimes", { params: { IDEmp: userId } })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return null;
      });
  },
  getProjectsByCompanyId(companyId) {
    return axios
      .get("/api/projects/getProjectsByCompany", { params: { noCie: companyId } })
      .then((data) => {
        return data.Projects;
      })
      .catch((err) => {
        return [];
      });
  },
  getCompanies() {
    return axios
      .get("/Companies")
      .then(async (data) => {
        let companies = data.Companies;
        let idx = 0;
        for (let company of companies) {
          try {
            const projects = await this.getProjectsByCompanyId(company.noCie);
            companies[idx].projectIds = projects.map((p) => p.ID);
          } catch (e) {}

          idx++;
        }
        return companies;
      })
      .catch((err) => {
        return [];
      });
  },
  getProjects(userId) {
    return axios
      .get("/Projects", { params: { IDEmp: userId } })
      .then((data) => {
        let projects = data.Projects || [];
        //get array of IDS
        //simplify data
        projects.forEach((project) => {
          project.operationsIds = project.Operations ? project.Operations.map((op) => op.ID) : [];
          project.activitiesIds = project.Activities ? project.Activities.map((op) => op.ID) : [];
        });

        return projects;
      })
      .catch((err) => {
        return [];
      });
  },
  createProject(number) {
    return axios({
      method: "post",
      url: "/api/projects/createProject",
      data: { noProjet: number },
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  getActivities() {
    return axios
      .get("/Activities")
      .then((data) => {
        let activities = data.Activities || [];

        //get array of IDS
        activities.forEach((activity) => {
          activity.operationsIds = activity.Operations ? activity.Operations.map((op) => op.ID) : [];
        });
        return activities;
      })
      .catch((err) => {
        return [];
      });
  },
  getOperations() {
    return axios
      .get("/Operations")
      .then((data) => {
        return data.Operations || [];
      })
      .catch((err) => {
        return [];
      });
  },
  getDefaultDepartments(userId) {
    return axios
      .get("/Departments", { params: { IDEmp: userId } })
      .then((data) => {
        return data.Departments || [];
      })
      .catch((err) => {
        return [];
      });
  },
  getDepartments() {
    return axios
      .get("/Departments")
      .then((data) => {
        return data.Departments || [];
      })
      .catch((err) => {
        return [];
      });
  },
  getDepartmentGroups() {
    return axios
      .get("/DepartmentGroups")
      .then((data) => {
        return data.DepartmentGroups || [];
      })
      .catch((err) => {
        return [];
      });
  },
  getDepartmentsByGroup(groupId) {
    return axios
      .get(`/DepartmentGroups/${groupId}/Departements`)
      .then((data) => {
        return data.Departments || [];
      })
      .catch((err) => {
        return [];
      });
  },
  getBranchs() {
    return axios
      .get("/Branch")
      .then((data) => {
        return data.Branches || [];
      })
      .catch((err) => {
        return [];
      });
  },
  getParams() {
    return axios
      .get("/Parameters")
      .then((data) => {
        let finalData = {};
        //Convert 1 to true
        //Convert 0 to false
        Object.keys(data).forEach((key) => {
          let value = data[key];
          if (value === 1) {
            finalData[key] = true;
            return;
          }
          if (value === 0) {
            finalData[key] = false;
            return;
          }
          finalData[key] = value;
        });
        return finalData;
      })
      .catch((err) => {
        return null;
      });
  },
  getSitePath() {
    return axios
      .get("/Parameters/SitePath")
      .then((data) => {
        return data.Path || "/";
      })
      .catch((err) => {
        return null;
      });
  },
  sendQuiz(data) {
    return axios({
      method: "post",
      url: "/api/covid/answers",
      data: data,
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return null;
      });
  },
  getNotifications(userId) {
    return axios
      .get(`/Api/Notifications/New/${userId}`)
      .then((data) => {
        return data;
        //  console.log(data)
      })
      .catch((err) => {});
  },
  getEmployees(userId) {
    return axios
      .get(`/Api/Employees/`)
      .then((data) => {
        return data.employeeList;
      })
      .catch((err) => {});
  },
  getEmployeesByProject(projectId) {
    return axios
      .get(`/Api/Employees/Project/${projectId}`)
      .then((data) => {
        return data.employeeList;
      })
      .catch((err) => {});
  },
  getEmployeesByBranch(branchId) {
    return axios
      .get(`/Api/Employees/branch/${branchId}`)
      .then((data) => {
        return data.employeeList;
      })
      .catch((err) => {});
  },
  getEmployeesByDepartment(departmentId) {
    return axios
      .get(`/Api/Employees/department/${departmentId}`)
      .then((data) => {
        return data.employeeList;
      })
      .catch((err) => {});
  }
};

export default DataAPI;
