import DataAPI from "globals/services/DataAPI";
import { isEmpty, sortAlphabetic } from "globals/utils/utils";
import { APP_TYPES } from "globals/utils/constant";
import EventBus, { EVENTS } from "globals/utils/eventbus";

const formMixin = {
  data() {
    return {
      companyId: null,
      projectId: null,
      activityId: null,
      operationId: null,
      branchId: null,
      departmentId: null,
      departmentGroupId: null,
      employeeIds: [],

      validationErrors: {},

      modals: {
        companies: false,
        projects: false,
        operations: false,
        branchs: false,
        activities: false,
        departments: false,
        departmentGroups: false,
        employees: false
      }
    };
  },
  watch: {
    projectId(nextValue, oldValue) {
      //if we use company and didnt select one, just dont run
      if (this.parameters && this.parameters.mobilePunchUseCompanies && !this.companyId) return;
      this.$nextTick(() => {
        this.selectFirstItemInSelect("projectId");
      });
    },
    companyId() {
      this.$nextTick(() => {
        this.selectFirstItemInSelect("companyId");
      });
    }
  },
  computed: {
    showMoreProjectInfoURL() {
      //only work online
      if (!this.$store.state.general.isOnline) {
        return false;
      }

      //only if we have the path
      if (isEmpty(this.$store.state.data.sitePath)) {
        return false;
      }

      //if we have a project
      if (isEmpty(this.projectId)) {
        return false;
      }

      return true;
    },
    companies() {
      if (window.appType === APP_TYPES.IPAD && !this.$store.state.general.isOnline) {
        this.$store.state.data.allCompanies;
      }
      return this.$store.state.data.companies;
    },
    projects() {
      if (window.appType === APP_TYPES.IPAD && !this.$store.state.general.isOnline) {
        this.$store.state.data.allProjects;
      }
      return this.$store.state.data.projects;
    },
    operations() {
      if (window.appType === APP_TYPES.IPAD && !this.$store.state.general.isOnline) {
        this.$store.state.data.allOperations;
      }
      return this.$store.state.data.operations;
    },
    branchs() {
      if (window.appType === APP_TYPES.IPAD && !this.$store.state.general.isOnline) {
        this.$store.state.data.allBranchs;
      }
      return this.$store.state.data.branchs;
    },
    departments() {
      if (window.appType === APP_TYPES.IPAD && !this.$store.state.general.isOnline) {
        this.$store.state.data.allDepartments;
      }
      return this.$store.state.data.departments;
    },
    departmentGroups() {
      if (window.appType === APP_TYPES.IPAD && !this.$store.state.general.isOnline) {
        this.$store.state.data.allDepartmentGroups;
      }
      return this.$store.state.data.departmentGroups;
    },
    activities() {
      if (window.appType === APP_TYPES.IPAD && !this.$store.state.general.isOnline) {
        this.$store.state.data.allActivities;
      }
      return this.$store.state.data.activities;
    },
    employees() {
      //no general version///

      return this.$store.state.data.employees;
    },
    parameters() {
      return this.$store.state.data.parameters;
    },
    projectSelected() {
      return this.projects.find((p) => p.ID === this.projectId);
    },
    companySelected() {
      return this.companies.find((c) => c.noCie === this.companyId);
    },
    operationSelected() {
      return this.operations.find((p) => p.ID === this.operationId);
    },
    branchSelected() {
      return this.branchs.find((p) => p.ID === this.branchId);
    },
    departmentSelected() {
      return this.departments.find((p) => p.ID === this.departmentId);
    },
    departmentGroupSelected() {
      return this.departmentGroups.find((p) => p.ID === this.departmentGroupId);
    },
    activitySelected() {
      return this.activities.find((p) => p.ID === this.activityId);
    },
    employeesSelected() {
      return this.employees.filter((e) => this.employeeIds.includes(e.idEmploye.toString()));
    },
    companiesOptions() {
      let options = this.companies.map((c) => ({
        label: c.nomCie.trim(),
        value: c.noCie
      }));
      options.sort(sortAlphabetic("label"));
      return options;
    },
    projectsOptions() {
      let projectsInsideCompany = this.projects;

      //if selected that mean we used company
      if (this.companySelected) {
        projectsInsideCompany = this.companySelected.projectIds.map((id) => {
          return this.projects.find((o) => o.ID === id);
        });
      }

      let options = [];
      projectsInsideCompany.forEach((p) => {
        if (isEmpty(p)) return;

        const name = p.Name.trim();
        let label = name;
        if (name !== p.No) {
          label = `#${p.No} - ${name}`;
        }
        options.push({
          label: label,
          value: p.ID
        });
      });
      options.sort(sortAlphabetic("label"));
      return options;
    },
    activitiesOptions() {
      if (!this.projectSelected) return [];
      let activitiesInsideProject = this.activities;

      //if empty array, get all instead
      if (!isEmpty(this.projectSelected.activitiesIds)) {
        activitiesInsideProject = this.projectSelected.activitiesIds.map((id) => {
          return this.activities.find((o) => o.ID === id);
        });
      }

      let options = [];
      activitiesInsideProject.forEach((p) => {
        if (isEmpty(p)) return;
        options.push({
          label: `${p.No ? p.No.trim() : ""} ${p.Name}`,
          value: p.ID
        });
      });
      options.sort(sortAlphabetic("label"));
      return options;
    },
    operationsOptions() {
      if (!this.activitySelected) return [];
      let operationsInsideActivity = this.operations;

      if (!isEmpty(this.activitySelected.operationsIds)) {
        operationsInsideActivity = this.activitySelected.operationsIds.map((id) => {
          return this.operations.find((o) => o.ID === id);
        });
      }

      let options = [];
      operationsInsideActivity.forEach((o) => {
        if (isEmpty(o)) return;
        options.push({
          label: `${o.No ? o.No.trim() : ""} ${o.Name}`,
          value: o.ID
        });
      });
      options.sort(sortAlphabetic("label"));
      return options;
    },
    branchsOptions() {
      let options = this.branchs.map((b) => ({
        label: `${b.No ? b.No.trim() : ""} ${b.Name}`,
        value: b.ID
      }));

      options.sort(sortAlphabetic("label"));
      return options;
    },
    departmentsOptions() {
      let options = this.departments.map((d) => ({
        label: d.Name.trim(),
        value: d.ID
      }));
      options.sort(sortAlphabetic("label"));
      return options;
    },
    employeesOptions() {
      //remove myself
      let options = this.employees
        .filter((e) => e.idEmploye !== this.$store.getters.userUniqueId)
        .map((d) => ({
          label: d.nomEmploye.trim(),
          value: d.idEmploye
        }));
      options.sort(sortAlphabetic("label"));
      return options;
    },
    departmentGroupsOptions() {
      let options = this.departmentGroups.map((d) => ({
        label: d.Name.trim(),
        value: d.ID
      }));
      options.sort(sortAlphabetic("label"));
      return options;
    },
    selectStrings() {
      let company = this.STRINGS.SELECT_COMPANIES;
      let project = this.STRINGS.SELECT_PROJECTS;
      let operation = this.STRINGS.SELECT_OPERATIONS;
      let activity = this.STRINGS.SELECT_ACTIVITIES;
      let branch = this.STRINGS.SELECT_BRANCH;
      let department = this.STRINGS.SELECT_DEPARTMENT;
      let employee = this.STRINGS.SELECT_EMPLOYEE;
      let departmentGroup = this.STRINGS.SELECT_DEPARTMENT_GROUP;

      //choose string if exist and get parameters strings
      //project
      if (this.parameters.projetListDescription && this.parameters.projetListDescriptionEn) {
        if (this.$store.state.auth.lang === "fr") {
          project = this.parameters.projetListDescription;
        } else {
          project = this.parameters.projetListDescriptionEn;
        }
      }

      //operation
      if (this.parameters.operationListDescription && this.parameters.operationListDescriptionEn) {
        if (this.$store.state.auth.lang === "fr") {
          operation = this.parameters.operationListDescription;
        } else {
          operation = this.parameters.operationListDescriptionEn;
        }
      }

      //activity
      if (this.parameters.activityListDescription && this.parameters.activityListDescriptionEn) {
        if (this.$store.state.auth.lang === "fr") {
          activity = this.parameters.activityListDescription;
        } else {
          activity = this.parameters.activityListDescriptionEn;
        }
      }

      //department
      if (this.parameters.departementListDescription && this.parameters.departementListDescriptionEn) {
        if (this.$store.state.auth.lang === "fr") {
          department = this.parameters.departementListDescription;
        } else {
          department = this.parameters.departementListDescriptionEn;
        }
      }

      //departmentGroup
      if (this.parameters.departmentGroupListDescription && this.parameters.departmentGroupListDescriptionEn) {
        if (this.$store.state.auth.lang === "fr") {
          departmentGroup = this.parameters.departmentGroupListDescription;
        } else {
          departmentGroup = this.parameters.departmentGroupListDescriptionEn;
        }
      }

      //branch todo

      return {
        company: company,
        project: project,
        operation: operation,
        activity: activity,
        branch: branch,
        department: department,
        departmentGroup: departmentGroup,
        employee: employee
      };
    }
  },
  methods: {
    projectInfoClick(e) {
      //so the event click dont go to the document
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      let endURL = `projet_selection.asp?IDProjet=${encodeURI(this.projectId)}&NoEmp=${encodeURI(
        this.$store.getters.userNIP
      )}`;

      //open new page
      open(this.$store.state.data.sitePath + endURL, "_blank");
    },
    selectFirstItemInSelect(editValueName) {
      this.$nextTick(() => {
        if (!this.parameters) return;
        //auto select project if we just change company
        if (this.parameters.mobilePunchUseCompanies && editValueName === "companyId") {
          if (this.projectsOptions.length > 0) {
            //if the already selected activityId is there, dont change it
            let found = this.projectsOptions.findIndex((a) => a.value === this.projectId) !== -1;

            //if not found, get the first value
            if (!found) {
              this.projectId = this.projectsOptions[0].value;
            }
          } else {
            this.projectId = null; //re  init project
          }
        }

        //only for when projectId
        if (editValueName !== "projectId") return;

        //select the first one, so the select input is not empty
        if (this.parameters.ActivitiesUsed) {
          if (this.activitiesOptions.length > 0) {
            //if the already selected activityId is there, dont change it
            let found = this.activitiesOptions.findIndex((a) => a.value === this.activityId) !== -1;

            //if not found, get the first value
            if (!found) {
              this.activityId = this.activitiesOptions[0].value;
            }
          }
        }

        //select the first one, so the select input is not empty
        if (this.parameters.DepartmentsUsed) {
          if (this.departmentsOptions.length > 0) {
            //if the already selected activityId is there, dont change it
            let found = this.departmentsOptions.findIndex((d) => d.value === this.departmentId) !== -1;
            if (!found) {
              this.departmentId = this.departmentsOptions[0].value;
            }
          }
        }

        if (this.parameters.useDepartmentGroups) {
          if (this.departmentGroupsOptions.length > 0) {
            //if the already selected activityId is there, dont change it
            let found = this.departmentGroupsOptions.findIndex((d) => d.value === this.departmentGroupId) !== -1;
            if (!found) {
              this.departmentGroupId = this.departmentGroupsOptions[0].value;
            }
          }
        }

        if (this.parameters.OperationsUsed) {
          if (this.operationsOptions.length > 0) {
            //if the already selected activityId is there, dont change it
            let found = this.operationsOptions.findIndex((o) => o.value === this.operationId) !== -1;
            if (!found) {
              this.operationId = this.operationsOptions[0].value;
            }
          }
        }

        //no default but still select the first one
        if (this.parameters.BranchUsed) {
          if (this.branchsOptions.length > 0) {
            //if the already selected activityId is there, dont change it
            let found = this.branchsOptions.findIndex((b) => b.value === this.branchId) !== -1;
            if (!found) {
              this.branchId = this.branchsOptions[0].value;
            }
          }
        }
      });
    },
    validatePunchData() {
      let errors = {};

      //Faire la validation
      if (this.parameters.ProjectUsed) {
        if (isEmpty(this.projectId)) {
          errors["projectId"] = this.STRINGS.VALIDATION_ERROR_PROJECT;
        }
      }
      if (this.parameters.ActivitiesUsed) {
        if (isEmpty(this.activityId)) {
          errors["activityId"] = this.STRINGS.VALIDATION_ERROR_ACTIVITY;
        }
      }
      if (this.parameters.BranchUsed) {
        if (isEmpty(this.branchId)) {
          errors["branchId"] = this.STRINGS.VALIDATION_ERROR_BRANCH;
        }
      }
      if (this.parameters.DepartmentsUsed) {
        if (isEmpty(this.departmentId)) {
          errors["departmentId"] = this.STRINGS.VALIDATION_ERROR_DEPARTMENT;
        }
      }
      if (this.parameters.useDepartmentGroups) {
        if (isEmpty(this.departmentGroupId)) {
          errors["departmentGroupId"] = this.STRINGS.VALIDATION_ERROR_DEPARTMENT_GROUP;
        }
      }
      if (this.parameters.OperationsUsed) {
        if (isEmpty(this.operationId)) {
          errors["operationId"] = this.STRINGS.VALIDATION_ERROR_OPERATION;
        }
      }

      return errors;
    },
    select(payload) {
      if (typeof this[payload.valueName] !== "undefined") {
        this[payload.valueName] = payload.value;
      }
    },
    closeModal(payload) {
      this.$set(this.modals, payload.name, false);
    },
    openModal(name) {
      EventBus.$emit(EVENTS.CHECK_FOR_UPDATED_DATA); //make sure we get latest data if needed

      this.$set(this.modals, name, true);
    },
    setDefaultValues() {
      //Set default ID
      const { DefaultActivityId, DefaultDepartmentId, DefaultOperationId, DefaultProjectId } =
        this.$store.state.auth.user;

      if (!this.parameters) return;
      if (this.parameters.mobilePunchUseCompanies) return; //dont run if use company

      if (this.parameters.ProjectUsed) {
        //that will trigger selectFirstItemInSelect because of the watch
        if (!isEmpty(DefaultProjectId)) {
          this.projectId = DefaultProjectId;
        } else {
          if (this.projectsOptions.length > 0) {
            //if we have a last punch, check if the id is in the list and select it, if not select the first one
            if (this.lastPunch) {
              let found = this.projectsOptions.findIndex((p) => p.value === this.lastPunch.idProjet) !== -1;
              if (found) {
                this.projectId = this.lastPunch.idProjet;
              } else {
                this.projectId = this.projectsOptions[0].value; //first one
              }
            } else {
              this.projectId = this.projectsOptions[0].value;
            }
          }
        }
      }

      //wait after selectFirstItemInSelect
      this.$nextTick(() => {
        if (this.parameters.ActivitiesUsed && !isEmpty(DefaultActivityId)) {
          //make sure its inside the options
          let found = this.activitiesOptions.findIndex((a) => a.value === DefaultActivityId) !== -1;
          if (found) {
            this.activityId = DefaultActivityId;
          }
        }
        if (this.parameters.DepartmentsUsed && !isEmpty(DefaultDepartmentId)) {
          let found = this.departmentsOptions.findIndex((d) => d.value === DefaultDepartmentId) !== -1;
          if (found) {
            this.departmentId = DefaultDepartmentId;
          }
        }
        if (!isEmpty(DefaultOperationId)) {
          let found = this.operationsOptions.findIndex((o) => o.value === DefaultOperationId) !== -1;
          if (found) {
            this.operationId = DefaultOperationId;
          }
        }

        if (this.parameters.BranchUsed) {
          if (this.branchsOptions.length > 0) {
            this.branchId = this.branchsOptions[0].value;
          }
        }
      });
    }
  }
};

export default formMixin;
