import axios from "axios";
import merge from "lodash-es/merge";
import * as types from "globals/store/types";
import { isEmpty } from "globals/utils/utils";
import { relogin } from "globals/store/relogin";
import { APP_TYPES } from "globals/utils/constant";

import { ROUTES as IPAD_ROUTES } from "ipad/router/routes";
import { ROUTES as MOBILE_ROUTES } from "mobile/router/routes";

//so we dont overwrite all axios global data
const axiosInstance = axios.create();

let SERVER_HTTP = "http://apptest.info-punch.com:5014";
let SERVER_HTTPS = "https://apptest.info-punch.com:44348";

//set to real server
if (process.env.NODE_ENV === "production") {
  SERVER_HTTP = "http://srv5.info-punch.com:5014";
  SERVER_HTTPS = "https://srv5.info-punch.com:44348";
}

const isHttps = window.location.protocol.includes("https");

axiosInstance.defaults.baseURL = isHttps ? SERVER_HTTPS : SERVER_HTTP;
//use the same protocol as the current website protocol

export const DEFAULT_HEADERS = {
  "Access-Control-Allow-Origin": "*",
  Accept: "application/json",
  "Content-Type": "application/x-www-form-urlencoded"
};
//init
axiosInstance.defaults.headers = merge({}, DEFAULT_HEADERS);

export const setHeaders = (headers) => {
  axiosInstance.defaults.headers = merge({}, DEFAULT_HEADERS, headers);
};

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (err) => {
    let res = err.response;

    if (!res) return Promise.reject(err);

    try {
      if (!isEmpty(res.data)) {
        window.vueStore.commit(types.AJAX_ERROR, res.data);
      }
    } catch (e) {}

    //catch server error
    if (res.status >= 500 && res.status < 600) {
      if (typeof window.Sentry !== "undefined") {
        //if sentry dont submit, dont make this func crash
        try {
          window.Sentry.captureException(err);
        } catch (e) {}
      }
    }

    //make setup if token is expired
    if (res.status === 401 && res.config && !res.config.__isRetryRequest__) {
      return new Promise((resolve, reject) => {
        //try to relogin user
        setHeaders({
          Authorization: ""
        });

        relogin(
          window.vueStore.state.auth.companyId,
          window.vueStore.state.auth.user.Nip,
          (success) => {
            let token = window.vueStore.state.auth.token.bearer;

            //put token in gloal axios
            setHeaders({
              Authorization: `Bearer ${token}`
            });
            //make sure we dont make a infinite loop
            err.config.__isRetryRequest__ = true;

            //put token
            err.config.headers.Authorization = `Bearer ${token}`;

            //retry the request
            resolve(axiosInstance(err.config));
          },
          (error) => {
            //go back to login
            if (window.appType === APP_TYPES.MOBILE) {
              window.vueRouter.replace(MOBILE_ROUTES.LOGIN).catch(() => {});
            } else if (window.appType === APP_TYPES.IPAD) {
              window.vueRouter.replace(IPAD_ROUTES.COMPANY_LOGIN).catch(() => {});
            }
            reject(error);
          }
        );
      });
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
